<template>
    <div>
        <gro-app-header height="60px">
            <div class="d-inline-flex m-auto">
                <img src="/gro-logo-white.svg" width="50"/>
            </div>
        </gro-app-header>

        <div class="row mt-3">
            <div class="col-11 col-sm-6 col-md-4 col-lg-4 col-xl-4 m-auto">
                <gro-card>
                    <div slot="body">
                        <h2 class="mb-3">Please sign in to your Gro account to continue</h2>
                        <gro-button id="login-button" @click.native="initAuthentication">Sign in with Gro</gro-button>
                    </div>
                </gro-card>
            </div>
        </div>
    </div>
</template>
<script>
    import {AuthStateModel} from "../models/state/AuthStateModel";
    import {PKCEMixin} from "../mixins/PKCEMixin";
    import {debugLog} from "../helpers/debugLog";

    export default {
        name: "Login",
        mixins: [PKCEMixin],
        data() {
            return {
                auth: {
                    gro: {
                        authorizeUrl: `${process.env.VUE_APP_GRO_AUTH_URL}/connect/authorize`,
                        code_verifier: null,
                        params: {
                            state: null,
                            response_type: 'code',
                            client_id: process.env.VUE_APP_GRO_CLIENT_ID,
                            code_challenge: null,
                            code_challenge_method: 'S256',
                            redirect_uri: process.env.VUE_APP_GRO_CLIENT_REDIRECT_URI,
                            scope: 'groapi  offline_access',
                            prompt: 'login'
                        }
                    }
                }
            }
        },
        methods: {
            initAuthentication() {
                let verifier = this.makeUniqueIdentifier(128);
                let challenge = this.base64URLEncode(this.sha256(verifier));
                let state = this.base64URLEncode(this.makeUniqueIdentifier(32));

                this.auth.gro.code_verifier = verifier;
                this.auth.gro.params.code_challenge = challenge;
                this.auth.gro.params.state = state;

                debugLog('oAuth Authorize URL', `${this.auth.gro.authorizeUrl}?${this.serialize(this.auth.gro.params)}`);

                window.localStorage.setItem('state', btoa(JSON.stringify(new AuthStateModel(verifier, challenge, state))));
                window.location.assign(`${this.auth.gro.authorizeUrl}?${this.serialize(this.auth.gro.params)}`);
            }
        }
    }
</script>